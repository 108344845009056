<template>
  <div>
    <div class="mb-2">
      <h2 class="text-center">Upload pictures below if you have any</h2>
      <p class="text-center">Max. {{ maxImages }} images</p>
      <image-uploader
        :attr="attrForField"
        :maxFiles="maxImages"
        :showResult="true"
        :url="url"
        :process-result="processUpload"
        :form-data="uploadFormData"
        :subscription-path="subscriptionPath"
        @update:loading="loading=$event"
      ></image-uploader>
    </div>
  </div>
</template>
<script>
import api from '@/clients/api'
import Spinner from '@/shared/spinner.vue'
import ImageUploader from "@/shared/uploaders/image.vue"
export default {
  props: {
    disputeId: {
      type: Number,
      required: true
    },
  },
  components: {
    ImageUploader,
    Spinner
  },
  data() {
    return {
      loading: false,
      maxImages: 10,
      imageIds: [],
    }
  },
  computed: {
    attrForField() {
      return {
        name: 'dispute_image[image]',
        key: 'id'
      }
    },
    subscriptionPath() {
      return '/cleaning_jobs/' + this.jobId + '/uploads'
    },
    uploadFormData() {
      return { dispute_id: this.disputeId }
    },
    url() {
      return "/api/dispute_images"
    },
  },
  methods: {
    processUpload(result, key) {
      this.imageIds.push(result[key])
      return result
    },
  }
}
</script>
