<template>
  <div class="px-2">
    <spinner v-if="loading" />
    <template v-else>
      <dispute-header :dispute="dispute" />
      <div class="row">
        <label class="col-sm-6">Property</label>
        <div class="col-sm-6">{{address}}</div>
      </div>
      <div class="row">
        <label class="col-sm-6">Time of Cleaning</label>
        <div class="col-sm-6">{{start}}</div>
      </div>
      <div class="row">
        <label class="col-sm-6">Requested resolution</label>
        <div class="col-sm-6">{{request}}</div>
      </div>
      <div v-if="percentage" class="row">
        <label class="col-sm-6">Percentage that was not cleaned properly</label>
        <div class="col-sm-6">{{percentage}} %</div>
      </div>
      <div class="row">
        <label class="col-sm-6">Feedback</label>
        <div class="col-sm-6">{{feedback}}</div>
      </div>
      <div class="row">
        <label class="col-sm-6">Client Paid</label>
        <div class="col-sm-6">{{clientPaid}} <a v-if="clientInvoiceUrl" class="btn btn-link py-0" target="_blank" :href="clientInvoiceUrl">Client Invoice</a><a v-if="cleanerInvoiceUrl" class="btn btn-link py-0" target="_blank" :href="cleanerInvoiceUrl">Cleaner Invoice</a></div>
      </div>
      <div class="row">
        <label class="col-sm-6">Cleaner</label>
        <div class="col-sm-6"><a :href="cleanerUrl" class="btn-link" target="_blank">{{cleaner}}</a></div>
      </div>
      <template v-if="!isClient">
        <div class="row">
          <label class="col-sm-6">Cleaner's Full Payment</label>
          <div class="col-sm-6">{{cleanerOriginalPayment}} ({{cleanerPercentage}}%)</div>
        </div>
        <div v-if="closed" class="row">
          <label class="col-sm-6">Cleaner Action</label>
          <div class="col-sm-6">{{savedCleanerAction}}</div>
        </div>
        <div v-if="closed" class="row">
          <label class="col-sm-6">Cleaner Deduction</label>
          <div class="col-sm-6">{{savedCleanerDeduction}}</div>
        </div>
      </template>
      <div class="row">
        <label class="col-sm-6">Email</label>
        <div class="col-sm-6">{{email}}</div>
      </div>
      <div class="row">
        <label class="col-sm-6">Phone</label>
        <div class="col-sm-6">{{phone}}</div>
      </div>
      <div class="row">
        <label class="col-sm-6">Dispute Id</label>
        <div class="col-sm-6">{{ dispute.id }}</div>
      </div>
      <div class="row">
        <label class="col-sm-6">Job Ref Id</label>
        <div class="col-sm-6"><a :href="visitUrl" class="btn btn-link p-0" target="_blank">{{ dispute.ref_id }}</a></div>
      </div>
      <template v-if="closed">
        <div v-if="savedReason" class="row">
          <label class="col-sm-6">Reason</label>
          <div class="col-sm-6">{{savedReason}}</div>
        </div>
      </template>
      <div v-for="image in images" class="row">
        <a target="_blank" :href="image.url" class="m-3"><img :src="image.thumb_url"></a>
      </div>
      <div class="row">
        <label class="col-sm-6">Cleaner's Response <small>{{ cleanerResponseBy }}</small></label>
        <div class="col-sm-6">{{ cleanerResponse }}</div>
      </div>
      <div v-if="dispute" class="row">
        <div class="col">
          <open-dispute-button :dispute="dispute" />
          <respond-form v-if="canPost" :dispute-id="dispute.id" @reload="$emit('reload')" />
          <resolution-form v-if="canClose" :dispute="dispute" @reload="$emit('reload')" />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import api from '@/clients/api'
import MyTextArea from '@/shared/inputs/text_area.vue'
import DisputeHeader from './header.vue'
import OpenDisputeButton from './open_button.vue'
import ResolutionForm from './resolution_form.vue'
import RespondForm from './respond_form.vue'
import Dropdown from '@/shared/inputs/select.vue'
import Amount from '@/shared/fields/amount.vue'
import Spinner from '@/shared/non_modal_spinner.vue'
import { formatCurrency } from '@/utils'
export default {
  props: {
    dispute: {
      type: Object,
      required: true
    }
  },
  components: {
    Amount, OpenDisputeButton, DisputeHeader, MyTextArea, ResolutionForm, RespondForm, Dropdown, Spinner
  },
  data() {
    return {
      cleanerAction: null,
      cleanerDeduction: null,
      loading: false,
      reason: '',
      options: [{
        value: '',
        label: "Please select an option"
      }, {
        value: 'no_pay',
        label: "Full Dispute: No pay"
      }, {
        value: 'half_pay',
        label: "Dispute Rate: Half the pay"
      }, {
        value: 'custom_amount',
        label: "Custom % or set amount deducted"
      }]
    }
  },
  computed: {
    canClose() {
      return this.isStaff && (this.status == 'open')
    },
    canPost() {
      return (this.status == 'open') && (this.isStaff || this.isCleaner) && (this.noCleanerResponse)
    },
    closed() {
      return this.status == 'closed'
    },
    address() {
      if (this.dispute) {
        return this.dispute.address
      }
    },
    cleaner() {
      if (this.dispute) {
        return this.dispute.cleaner
      }
    },
    cleanerUrl() {
      if (this.dispute) {
        return "/admin/cleaners/" + this.dispute.cleaner_id
      }
    },
    clientUrl() {
      if (this.dispute) {
        return "/admin/clients/" + this.dispute.client_id
      }
    },
    email() {
      if (this.dispute) {
        return this.dispute.email
      }
    },
    feedback() {
      if (this.dispute) {
        return this.dispute.feedback
      }
    },
    images() {
      if (this.dispute) {
        return this.dispute.images
      }
    },
    savedCleanerAction() {
      if (this.dispute) {
        return this.dispute.cleaner_action
      }
    },
    savedCleanerDeduction() {
      if (this.dispute) {
        return this.dispute.cleaner_deduction
      }
    },
    noCleanerResponse() {
      if (this.dispute) {
        return this.dispute.cleaner_response == null
      }
    },
    cleanerResponse() {
      if (this.noCleanerResponse) {
        return 'No response'
      }
      return this.dispute.cleaner_response
    },
    cleanerResponseBy() {
      if (!this.noCleanerResponse) {
        return "(Added by " + this.dispute.whoresponded + ")"
      }
    },
    cleanerPercentage() {
      if (this.dispute) {
        return this.dispute.cleaner_percentage
      }
    },
    cleanerTotalCents() {
      if (this.dispute) {
        return this.dispute.cleaner_receives
      }
    },
    cleanerOriginalPayment() {
      return formatCurrency(this.cleanerTotalCents / 100)
    },
    cleanerReceives() {
      return formatCurrency(this.cleanerReceivesCents / 100)
    },
    cleanerDeductionCents() {
      var cents = 0
      if (this.cleanerAction == 'no_pay') {
        cents = this.cleanerTotalCents
      }
      if (this.cleanerAction == 'half_pay') {
        cents = this.cleanerTotalCents / 2
      }
      if (this.cleanerDeduction && this.cleanerAction == 'custom_amount') {
        if (this.cleanerDeduction.includes('%')) {
          cents = this.cleanerTotalCents - this.cleanerTotalCents * (Math.abs(parseInt(this.cleanerDeduction)) / 100.0)
        } else {
          cents = Math.abs(this.cleanerDeduction) * 100
        }
      }
      return Math.trunc(cents)
    },
    cleanerReceivesCents() {
      return this.cleanerTotalCents - this.cleanerDeductionCents
    },
    cleanerInvoiceUrl() {
      return "/cleaner_invoices/" + this.dispute.cleaner_invoice_id
    },
    clientInvoiceUrl() {
      return "/client_invoices/" + this.dispute.client_invoice_id
    },
    clientPaid() {
      if (this.dispute) {
        return formatCurrency(this.dispute.client_paid / 100)
      }
    },
    percentage() {
      if (this.dispute) {
        return this.dispute.percentage
      }
    },
    phone() {
      if (this.dispute) {
        return this.dispute.phone
      }
    },
    request() {
      if (this.dispute) {
        return this.dispute.request
      }
    },
    savedReason() {
      if (this.dispute) {
        return this.dispute.reason
      }
    },
    start() {
      if (this.dispute) {
        return this.dispute.start
      }
    },
    status() {
      if (this.dispute) {
        return this.dispute.status
      }
    },
    visitUrl() {
      return "/properties/" + this.dispute.property_id + '/visits/' + this.dispute.reservation_id
    },
  },
  methods: {
    submit(e) {
      const disputeAction = e.submitter.dataset.action
      var params = {
        reason: this.reason,
        cleaner_action: this.cleanerAction,
        cleaner_deduction_cents: this.cleanerDeductionCents
      }
      return new Promise((resolve, reject) => {
        this.loading = true
        api.closeDispute(this.dispute.id, disputeAction, params).then(response => {
          this.show = false
          this.loading = false
          this.$emit('reload')
        }).catch((error) => {
          this.loading = false
          this.show = false
          this.$emit('reload')
          this.$flasher.errors(error.response.data.errors)
        })
        return resolve()
      })
    }
  }
}
</script>
