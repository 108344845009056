<template>
  <my-form :start-in-edit-mode="true" :report-success="false">
    <template v-slot:default="{invalid, valid, errors, formIsReadonly }">
      <dropdown v-model="cleanerAction" :options="options" rules="required" label="How the cleaner's invoice should be affected?" cssClass=""></dropdown>
      <amount
        v-if="cleanerAction == 'custom_amount'"
        v-model="cleanerDeduction"
        type="text"
        label="Enter a percentage or an amount to reduce the cleaner's payment, eg '50%' or '40'"
      ></amount>
      <div class="row">
        <label class="col-sm-6">Cleaner's Adjusted Payment</label>
        <div class="col-sm-6">{{cleanerReceives}}</div>
      </div>
      <my-text-area v-model="reason" name="explanation" rules="required|min:10" label="Enter an explanation for your resolution"></my-text-area>
    </template>
    <template v-slot:edit-buttons="{ invalid, validated }">
      <button class="btn btn-success" type="submit" data-action="approve"
        :disabled="invalid && validated">Approve</button>
      <button class="btn btn-danger" type="submit" data-action="decline"
        :disabled="invalid && validated">Decline</button>
    </template>
  </my-form>
</template>
<script>
import api from '@/clients/api'
import MyTextArea from '@/shared/inputs/text_area.vue'
import MyForm from '@/shared/base_form.vue'
import Dropdown from '@/shared/inputs/select.vue'
import Spinner from '@/shared/non_modal_spinner.vue'
import { formatCurrency } from '@/utils'
export default {
  props: {
    dispute: {
      type: Object,
      required: true
    }
  },
  components: {
    MyTextArea, MyForm, Dropdown, Spinner
  },
  data() {
    return {
      cleanerAction: null,
      cleanerDeduction: null,
      loading: false,
      reason: '',
      options: [{
        value: '',
        label: "Please select an option"
      }, {
        value: 'no_pay',
        label: "Full Dispute: No pay"
      }, {
        value: 'half_pay',
        label: "Dispute Rate: Half the pay"
      }, {
        value: 'custom_amount',
        label: "Custom % or set amount deducted"
      }]
    }
  },
  computed: {
    canClose() {
      return this.isStaff && (this.status == 'open')
    },
    canOpen() {
      return this.isStaff && (this.status != 'open')
    },
    closed() {
      return this.status == 'closed'
    },
    createdAt() {
      if (this.dispute) {
        return this.dispute.created_at
      }
    },
    address() {
      if (this.dispute) {
        return this.dispute.address
      }
    },
    cleaner() {
      if (this.dispute) {
        return this.dispute.cleaner
      }
    },
    cleanerUrl() {
      if (this.dispute) {
        return "/admin/cleaners/" + this.dispute.cleaner_id
      }
    },
    client() {
      if (this.dispute) {
        return this.dispute.client
      }
    },
    clientUrl() {
      if (this.dispute) {
        return "/admin/clients/" + this.dispute.client_id
      }
    },
    email() {
      if (this.dispute) {
        return this.dispute.email
      }
    },
    feedback() {
      if (this.dispute) {
        return this.dispute.feedback
      }
    },
    images() {
      if (this.dispute) {
        return this.dispute.images
      }
    },
    savedCleanerAction() {
      if (this.dispute) {
        return this.dispute.cleaner_action
      }
    },
    savedCleanerDeduction() {
      if (this.dispute) {
        return this.dispute.cleaner_deduction
      }
    },
    cleanerPercentage() {
      if (this.dispute) {
        return this.dispute.cleaner_percentage
      }
    },
    cleanerTotalCents() {
      if (this.dispute) {
        return this.dispute.cleaner_receives
      }
    },
    cleanerOriginalPayment() {
      return formatCurrency(this.cleanerTotalCents / 100)
    },
    cleanerReceives() {
      return formatCurrency(this.cleanerReceivesCents / 100)
    },
    cleanerDeductionCents() {
      var cents = 0
      if (this.cleanerAction == 'no_pay') {
        cents = this.cleanerTotalCents
      }
      if (this.cleanerAction == 'half_pay') {
        cents = this.cleanerTotalCents / 2
      }
      if (this.cleanerDeduction && this.cleanerAction == 'custom_amount') {
        if (this.cleanerDeduction.includes('%')) {
          cents = this.cleanerTotalCents - this.cleanerTotalCents * (Math.abs(parseInt(this.cleanerDeduction)) / 100.0)
        } else {
          cents = Math.abs(this.cleanerDeduction) * 100
        }
      }
      return Math.trunc(cents)
    },
    cleanerReceivesCents() {
      return this.cleanerTotalCents - this.cleanerDeductionCents
    },
    cleanerInvoiceUrl() {
      return "/cleaner_invoices/" + this.dispute.cleaner_invoice_id
    },
    clientInvoiceUrl() {
      return "/client_invoices/" + this.dispute.client_invoice_id
    },
    clientPaid() {
      if (this.dispute) {
        return formatCurrency(this.dispute.client_paid / 100)
      }
    },
    percentage() {
      if (this.dispute) {
        return this.dispute.percentage
      }
    },
    phone() {
      if (this.dispute) {
        return this.dispute.phone
      }
    },
    request() {
      if (this.dispute) {
        return this.dispute.request
      }
    },
    savedReason() {
      if (this.dispute) {
        return this.dispute.reason
      }
    },
    start() {
      if (this.dispute) {
        return this.dispute.start
      }
    },
    status() {
      if (this.dispute) {
        return this.dispute.status
      }
    },
    createdInfo() {
      if (this.dispute) {
        let info = 'created '
        if (this.dispute.whodunnit) {
          info = info + 'by ' + this.dispute.whodunnit + ' '
        }
        info = info + 'at ' + this.createdAt
        return info
      }
    },
    visitUrl() {
      return "/properties/" + this.dispute.property_id + '/visits/' + this.dispute.reservation_id
    },
  },
  methods: {
    openDispute() {
      this.loading = true
      api.openDispute(this.dispute.id).then(response => {
        this.show = false
        this.loading = false
        this.$emit('reload')
      })
    },
    submit(e) {
      const disputeAction = e.submitter.dataset.action
      var params = {
        reason: this.reason,
        cleaner_action: this.cleanerAction,
        cleaner_deduction_cents: this.cleanerDeductionCents
      }
      return new Promise((resolve, reject) => {
        this.loading = true
        api.closeDispute(this.dispute.id, disputeAction, params).then(response => {
          this.show = false
          this.loading = false
          this.$emit('reload')
        }).catch((error) => {
          this.loading = false
          this.show = false
          this.$emit('reload')
          this.$flasher.errors(error.response.data.errors)
        })
        return resolve()
      })
    }
  }
}
</script>
