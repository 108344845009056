<template>
  <h1>
    <dispute-status :status="dispute.status"></dispute-status> Dispute for {{ client }}, {{ createdInfo }}
  </h1>
</template>
<script>
import DisputeStatus from './status.vue'
export default {
  props: {
    dispute: {
      type: Object,
      required: true
    }
  },
  components: {
    DisputeStatus
  },
  computed: {
    client() {
      if (this.dispute) {
        return this.dispute.client
      }
    },
    createdAt() {
      if (this.dispute) {
        return this.dispute.created_at
      }
    },
    updatedAt() {
      if (this.dispute) {
        return this.dispute.updated_at
      }
    },
    createdInfo() {
      if (this.dispute) {
        let info = 'started '
        if (this.dispute.whostartedit) {
          info = info + 'by ' + this.dispute.whostartedit + ' '
        }
        info = info + 'at ' + this.createdAt
        if (this.dispute.whoclosedit) {
          info = info + ', closed by ' + this.dispute.whoclosedit + ' at ' + this.updatedAt
        }
        return info
      }
    },
  }
}
</script>
