<template>
  <my-form :start-in-edit-mode="true" :report-success="false">
    <template v-slot:default="{invalid, valid, errors, formIsReadonly }">
      <small>Add images or post a response below</small>
      <my-text-area v-model="response" name="response" rules="required|min:10"></my-text-area>
      <add-images :dispute-id="disputeId"></add-images>
    </template>
  </my-form>
</template>
<script>
import api from '@/shared/api'
import MyTextArea from '@/shared/inputs/text_area.vue'
import MyForm from '@/shared/base_form.vue'
import AddImages from './add_images.vue'
import Spinner from '@/shared/non_modal_spinner.vue'
export default {
  props: {
    disputeId: {
      type: Number,
      required: true
    }
  },
  components: {
    MyTextArea, MyForm, Spinner, AddImages
  },
  data() {
    return {
      loading: false,
      response: '',
    }
  },
  computed: {
  },
  methods: {
    submit(e) {
      let params = {
        cleaner_response: this.response
      }
      return new Promise((resolve, reject) => {
        this.loading = true
        api.respondToDispute(this.disputeId, params).then(response => {
          this.loading = false
          this.$emit('reload')
        }).catch((error) => {
          this.loading = false
          this.$emit('reload')
          this.$flasher.errors(error.response.data.errors)
        })
        return resolve()
      })
    }
  }
}
</script>
