<template>
  <h5 v-if="status" class="text-center">
    <span v-if="isNew" class="badge badge-pill badge-success">New</span>
    <span v-if="isOpen" class="badge badge-pill badge-primary">Open</span>
    <span v-if="isClosed" class="badge badge-pill badge-gray">Closed</span>
  </h5>
</template>
<script>
import {
  isAdmin,
  isAgent,
  isClient,
  isCleaner
} from '../utils'
export default {
  props: {
    status: {
      type: String
    }
  },
  computed: {
    isNew() {
      return this.status == 'new'
    },
    isOpen() {
      return this.status == 'open'
    },
    isClosed() {
      return this.status == 'closed'
    },
  }
}
</script>
